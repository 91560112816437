html {
  box-sizing: border-box;
}

body {
  background: #0784ab;
  color:#0b2641;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #0784ab #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #0784ab;
  border-radius: 10px;
  border: 3px solid #ffffff;
}